import { TDynamicHomeSettingResponse } from "../../../../api/types/dynamic-home";

export type settingItem = {
  id: string;
  key: string;
  order: number;
  count: number;
};

export type TSetting = {
  websiteSetting: settingItem[];
  appSetting: settingItem[];
};

const getItem = (
  setting: TDynamicHomeSettingResponse,
  itemKey: string,
): settingItem | undefined => {
  const orderItem = setting.find((item) => item.name === itemKey + "_order");
  const countItem = setting.find((item) => item.name === itemKey + "_count");
  if (orderItem && countItem) {
    return {
      id: itemKey,
      key: itemKey,
      order: orderItem.value,
      count: countItem.value,
    };
  }
};
export const mapFetchedSetting = (setting: TDynamicHomeSettingResponse) => {
  // website
  const websiteSetting = [];
  const websiteCategory = getItem(setting, "website-category-section");
  const websiteJob = getItem(setting, "website-job-section");
  const websiteFreelancer = getItem(setting, "website-freelancer-section");
  const websitePackage = getItem(setting, "website-package-section");
  const websiteProFreelancer = getItem(
    setting,
    "website-pro-freelancer-section",
  );

  if (
    websiteCategory &&
    websiteJob &&
    websiteFreelancer &&
    websitePackage &&
    websiteProFreelancer
  ) {
    websiteSetting.push(
      websiteCategory,
      websiteJob,
      websiteFreelancer,
      websitePackage,
      websiteProFreelancer,
    );
  }

  // app
  const appSetting = [];
  const appCategory = getItem(setting, "app-category-section");
  const appJob = getItem(setting, "app-job-section");
  const appFreelancer = getItem(setting, "app-freelancer-section");
  const appPackage = getItem(setting, "app-package-section");
  const appProFreelancer = getItem(setting, "app-pro-freelancer-section");

  if (
    appCategory &&
    appJob &&
    appFreelancer &&
    appPackage &&
    appProFreelancer
  ) {
    appSetting.push(
      appCategory,
      appJob,
      appFreelancer,
      appPackage,
      appProFreelancer,
    );
  }
  return {
    websiteSetting: websiteSetting.sort((a, b) => a.order - b.order),
    appSetting: appSetting.sort((a, b) => a.order - b.order),
  };
};

export const getSectionTitle = (key: string): string => {
  if (key === "website-category-section" || key === "app-category-section")
    return "Category";
  if (key === "website-job-section" || key === "app-job-section") return "Job";
  if (key === "website-freelancer-section" || key === "app-freelancer-section")
    return "Freelancer";
  if (key === "website-package-section" || key === "app-package-section")
    return "Package";
  if (
    key === "website-pro-freelancer-section" ||
    key === "app-pro-freelancer-section"
  )
    return "Pro Freelancer";
  return "";
};
